import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AgmCoreModule } from '@agm/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './routes/home/home.component';
import { AboutComponent } from './routes/about/about.component';
import { ContactComponent } from './routes/contact/contact.component';
import {FormsModule} from '@angular/forms';
import {LanguageService} from './services/language.service';
import {ProductsRoutingModule} from './routes/products/products-routing.module';
import { AvailabilityComponent } from './routes/availability/availability.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import { NetworkComponent } from './routes/network/network.component';
import {ProductsModule} from './routes/products/products.module';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TvRadioComponent } from './routes/tv-radio/tv-radio.component';
import { SocialMediaComponent } from './routes/social-media/social-media.component';



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    AvailabilityComponent,
    NetworkComponent,
    TvRadioComponent,
    SocialMediaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ProductsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ProductsRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCYhGeO7CNBS9Pv-VkTqZ0I4NRF5vXiuDU'
    })
  ],
  providers: [LanguageService, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
