import { Component, OnInit } from '@angular/core';
import {AboutUsService} from '../../services/about-us.service';
import {Subscription} from 'rxjs';
import {About} from '../../interfaces/model';
import {environment} from '../../../environments/environment.prod';

declare const $: any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  aboutSub: Subscription;
  aboutData: About = {
    image: '',
    body: ''
  };
  media = environment.media + 'gallery/';

  constructor(private about: AboutUsService) { }

  ngOnInit(): void {

    window.scrollTo(0, 0);

    this.getAbout();
  }

  getAbout() {
    this.about.getAbout().subscribe(res => {
      this.aboutData = res.data;
      $('.loader').fadeOut(200);
    }, err => console.log(err));
  }

}
