import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SocialMediaService} from '../../services/social-media.service';
import {GetPlans, Request} from '../../interfaces/model';
import {Subscription} from 'rxjs';
import Uikit from 'uikit';
import {NgForm} from "@angular/forms";

declare const $: any;

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent implements OnInit, OnDestroy {
  @ViewChild('contactForm', {static: false}) contactForm: NgForm;

  // variables
  plansSub: Subscription;
  headerImage: string;
  // arraies
  plansContainer: GetPlans[] = [];
  messageIsBeingSend: boolean;

  messageContainer: Request = {
    name: '',
    address: '',
    company: '',
    email: '',
    id_item: null,
    phone: null,
    type: ''
  };

  constructor(private social: SocialMediaService) {
  }

  ngOnInit(): void {
    this.messageIsBeingSend = false;
    window.localStorage.getItem('dir') === 'ltr' ? this.headerImage = 'assets/images/tv-radio.svg' : this.headerImage = 'assets/images/social-media-rtl.svg';
    window.scrollTo(0, 0);
    this.getAllPlans();
  }

  ngOnDestroy(): void {

  }

  getAllPlans() {
    this.plansSub = this.social.getAllPlans(localStorage.getItem('dir') === 'ltr' ? 1 : 0).subscribe(res => {
      this.plansContainer = res.data;
      $('.loader').fadeOut(200);
      console.log(this.plansContainer);
    });
  }

  sendMessage() {
    console.log(this.messageContainer);
    this.messageIsBeingSend = true;
    this.social.makeRequest(this.messageContainer).subscribe(() => {
      $('.modal').modal('toggle');
      Uikit.notification('We have received your request', {pos: 'top-center'});
      this.contactForm.reset();
      this.messageIsBeingSend = false;
    }, err => console.log(err));
  }
}
