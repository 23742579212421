<section class="tv-radio" [ngClass]="{'ar': isArabic, 'en': !isArabic}">

  <div class="loader main-loader">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>

  <div class="upper-part mb-5" [ngStyle]="{'background-image': 'url(' + headerImage + ')'}">
    <div class="container">
      <h2 class="content-caption" translate>tv-radio</h2>
    </div>
  </div>

  <div class="description-part mb-5">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 desc-info">
          <h3 class="desc-header font-weight-bold" translate>tv-description-header</h3>
          <p class="desc-content" translate="">tv-description</p>
        </div>
        <div class="col-md-6 d-flex justify-content-center">
          <img src="assets/images/tv-radio-desc.svg" width="500" alt="illustration">
        </div>
      </div>
    </div>
  </div>

  <div class="channels">
    <div class="container">
      <div class="tv-channels">
        <header class="header d-flex mb-3">
          <h2 class="header-text mb-0 tv" translate>tv-channels</h2>
        </header>
        <div class="channel-list">
          <div class="row">
            <div class="col-sm-6 col-md-4 mb-3" *ngFor="let tv of tvsContainer">
              <div class="channel">
                <div class="media-top p-3 d-flex align-items-center justify-content-center">
                  <img [src]="imgPath+tv.image" alt="tv-channel">
                </div>
                <div class="subscribe text-center p-4">
                  <button class="btn tv"
                          translate
                          (click)="messageContainer.type = 'tv'; messageContainer.id_item = tv.id"
                          data-toggle="modal"
                          data-target="#modelId"
                  >subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="radio-channels">
        <header class="header d-flex mb-3">
          <h2 class="header-text mb-0 radio" translate>radio-channels</h2>
        </header>
        <div class="channel-list">
          <div class="row">
            <div class="col-sm-6 col-md-4 mb-3" *ngFor="let radio of radioContainer">
              <div class="channel">
                <div class="media-top p-3 d-flex align-items-center justify-content-center">
                  <img [src]="imgPath+radio.image" alt="radio-channel">
                </div>
                <div class="subscribe text-center p-4">
                  <button class="btn radio"
                          translate
                          data-toggle="modal"
                          (click)="messageContainer.type = 'tv'; messageContainer.id_item = radio.id"
                          data-target="#modelId"
                  >subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</section>

<!-- Modal -->
<div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <form class="modal-dialog form" role="document" #contactForm="ngForm" (ngSubmit)="sendMessage()">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center justify-content-between">
        <h5 class="modal-title" translate>enter-info</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="">
              <p class="text-muted mb-5">{{'contactPage.form-des' | translate}}</p>

              <div>
                <div class="form-group">

                  <div class="row ">
                    <div class="col-12 col-sm-6 mb-4">
                      <input name="name" type="text" [class.invalid]=""
                             [placeholder]="'contactPage.name-placeholder'| translate" class="form-control"
                             required [(ngModel)]="messageContainer.name">
                    </div>
                    <div class="col-12 col-sm-6 mb-4">
                      <input name="phone" [class.invalid]="" onkeypress="validate(event)" type="tele"
                             [placeholder]="'contactPage.phone-placeholder'| translate"
                             class="form-control" required [(ngModel)]="messageContainer.phone">
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-sm-6 mb-4">
                      <input type="email" #email email name="email"
                             [placeholder]="'contactPage.mail-placeholder'| translate"
                             class="form-control" required [(ngModel)]="messageContainer.email">
                      <small class="text-danger" *ngIf="contactForm.controls.email?.errors?.email">is not an email
                        format
                      </small>
                    </div>
                    <div class="col-12 col-sm-6 mb-4">
                      <input type="text" [class.invalid]="" name="company"
                             [placeholder]="'contactPage.comp-placeholder'| translate" class="form-control"
                             required [(ngModel)]="messageContainer.company">
                    </div>
                  </div>

                  <textarea name="body" [class.invalid]="" [placeholder]="'contactPage.message-placeholder'| translate"
                            class="form-control"
                            rows="5" [(ngModel)]="messageContainer.address" required></textarea>


                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn close-btn btn-secondary" data-dismiss="modal" translate>close</button>
        <button type="submit" class="btn submit"
                [disabled]="messageIsBeingSend || contactForm.invalid">{{(messageIsBeingSend ? 'contactPage.form-btn-loading' : 'contactPage.form-btn') | translate}}</button>
      </div>
    </div>
  </form>
</div>
