import {AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Categories} from "../../interfaces/model";
import {CategoriesService} from "../../services/categories.service";

declare const $: any;
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy, AfterContentInit {

  // categories = [
  //   {name: 'Rooftop', id: 1},
  //   {name: 'Bridges', id: 2},
  //   {name: 'Mupis', id: 3},
  //   {name: 'Backlits', id: 4}
  // ];

  isArabic: boolean;
  categoryList: Categories[] = [];

  constructor(private categories: CategoriesService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    localStorage.getItem('dir') === 'ltr' ? this.isArabic = false : this.isArabic = true;
    this.getAllCategories();
  }

  ngOnDestroy(): void {
  }

  ngAfterContentInit(): void {
    // this.scrollToRoutingSection();
  }

  scrollToRoutingSection() {
    const element = document.querySelector('#routing');
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  getAllCategories() {
    this.categories.getCategories().subscribe(res => {
      this.categoryList = res.data;
      console.log(res);
      $('.loader').fadeOut(200);
    }, err => console.log(err));
  }


}
