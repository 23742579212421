<section class="content global" [ngClass]="{'en': !isArabic, 'ar': isArabic}">
  <div class="loader main-loader">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>


  <div class="container">
    <!--  product preview-->
    <div class="product-preview uk-card uk-card-default">
      <div class="image" uk-lightbox>
        <a [href]="media + productDetails?.image">
          <img [src]="media + productDetails?.image" alt="img" class="img-fluid">
        </a>
      </div>
      <div class="info">
        <div class="d-flex align-items-start justify-content-around flex-column">
          <div class="location d-flex align-items-baseline">
            <i class="fa fa-barcode" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;
            <h3 class="uk-margin-remove text-uppercase">{{productDetails.code}}</h3>
          </div>
          <div class="code  d-flex align-items-baseline">
            <i class="fa fa-map-marker-alt" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <h3 class="uk-margin-remove">{{productDetails.name}}</h3>
          </div>
        </div>
      </div>
    </div>

    <!--  product specification-->
    <section class="specification">
      <div class="uk-card uk-card-default">
        <div class="uk-card-body">
          <h3 class="header-text">{{'productPage.specifications' | translate}}</h3>
          <div class="uk-overflow-auto">
            <table class="uk-table uk-table-small uk-table-divider">
              <thead>
              <tr>
                <th>Code</th>
                <th>Direction</th>
                <th>W*H(m)</th>
                <th>Number of faces</th>
                <th>Gross price / face / month/($)</th>
                <th>Production/face ($)</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{productDetails?.code}}</td>
                <td>{{productDetails?.direction}}</td>
                <td>{{productDetails?.space}}</td>
                <td>{{productDetails?.faces}}</td>
                <td>{{productDetails?.price}}</td>
                <td>{{productDetails?.Production}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>

</section>
