import {Component, OnDestroy, OnInit} from '@angular/core';
import {Categories, HomeData, Services, Slider} from '../../interfaces/model';
import {GalleryService} from '../../services/gallery.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment.prod';
import {HomeService} from '../../services/home.service';
import {CategoriesService} from "../../services/categories.service";

declare const $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  images: Slider[] = [];
  categoryList: Categories[] = [];
  homeData: HomeData = {
    news: []
  };
  gallerySub: Subscription;
  homeDataSub: Subscription;
  servicesSub: Subscription;
  media = environment.media + 'gallery/';
  services: Services = {
    body: '',
    image: ''
  };
  isArabic: boolean;


  constructor(private gallery: GalleryService, private home: HomeService, private categories: CategoriesService) {
  }

  ngOnInit(): void {
    window.localStorage.getItem('dir') === 'ltr' ? this.isArabic = false : this.isArabic = true;
    window.scrollTo(0, 0);
    this.getGallery();
    this.getServices();
    this.getAllCategories();
    this.getHomeData();
  }

  ngOnDestroy(): void {
    this.gallerySub.unsubscribe();
    this.homeDataSub.unsubscribe();
    this.servicesSub.unsubscribe();
  }

  getGallery() {
    this.gallerySub = this.gallery.getGallery().subscribe(res => {
      this.images = res.data.data;
      console.log(res);
    }, err => console.log(err));
  }

  getHomeData() {
    this.homeDataSub = this.home.getHomeData().subscribe(res => {
      this.homeData = res.data;
      $('.loader').fadeOut(200);
      console.log(this.homeData);
    }, err => console.log(err));
  }

  getServices() {
    this.servicesSub = this.home.getServices().subscribe(res => {
      this.services = res.data;
    });
  }

  getAllCategories() {
    this.categories.getCategories().subscribe(res => {
      this.categoryList = res.data;
    }, err => console.log(err));
  }

}
