<section class="content">
  <div class="loader main-loader">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
  <header>
    <div class="uk-cover-container">
      <canvas width="" height="350"></canvas>
      <img src="assets/images/02.jpg" alt="" uk-cover>
      <div class="uk-overlay uk-position-cover overlay"></div>
      <div class="uk-overlay uk-position-center overlay-text">
        <h2 class="text">{{'aboutPage.header-text' | translate}}</h2>
      </div>
    </div>
  </header>

  <!--  About Section-->
  <section class="about">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 d-flex align-items-center">
          <div class="left-section">
            <h4 class="text-uppercase colored">{{'aboutPage.who-we-are' | translate}}</h4>
            <div class="header">
              <h2 class="header-text">{{'aboutPage.full-service' | translate}}</h2>
            </div>
            <div class="text" [innerHTML]="aboutData?.body">


            </div>
          </div>
        </div>
        <!--        right section -->
        <div class="col-lg-5 offset-lg-1">
          <div class="right-section">
            <div class="img" [ngStyle]="{'background-image': 'url(' + media + aboutData?.image +')'}">
<!--              <img [src]="media + aboutData?.image" alt="image" class="img-fluid">-->

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--  last section -->
  <section class="last">
    <div class="uk-cover-container">
      <canvas width="" height="350"></canvas>
      <img src="assets/images/bg.jpg" alt="" uk-cover>
      <div class="uk-overlay uk-position-cover overlay"></div>
      <div class="uk-overlay uk-position-cover overlay-text d-flex align-items-center">
        <div class="container">
          <div>
            <h4 class="header text-uppercase">{{'aboutPage.request-quota' | translate}}</h4>
            <div class="d-flex align-items-center justify-content-between flex-wrap">
              <h2 class="text">{{('aboutPage.last-section' | translate) | slice:0:24}}
                <br/>{{('aboutPage.last-section' | translate) | slice:24}}</h2>

              <button class="btn" routerLink="/contact-us">{{'aboutPage.contact-btn' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
