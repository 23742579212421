import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor(private http: HttpClient) { }

  static getLanguage(): number {
    if (localStorage.getItem('dir') === 'ltr') {
      return 1;
    } else {
      return 0;
    }
  }

  getLocations(): Observable<any> {
    return this.http.get(`${environment.proxyServer + environment.apiUrl}dashboard/all_locations`);
  }


}
