import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../services/language.service';
import {TranslateService} from "@ngx-translate/core";
import {CategoriesService} from "../../services/categories.service";
import {Categories} from "../../interfaces/model";

declare const $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  window = window;
  isArabic: boolean;
  categoryList: Categories[] = [];

  constructor( private translate: TranslateService, private categories: CategoriesService) {
  }

  setArabic() {
    if (LanguageService.getDirection() !== 'rtl') {
      LanguageService.setArabicDirection();
      this.translate.use('ar');
    }
  }

  setEnglish() {
    if (LanguageService.getDirection() !== 'ltr') {
      LanguageService.setEnglishDirection();
      this.translate.use('en');
    }
  }


  ngOnInit(): void {
    window.localStorage.getItem('dir') === 'ltr' ? this.isArabic = false : this.isArabic = true;
    this.getAllCategories();

  }

  getAllCategories() {
    this.categories.getCategories().subscribe(res => {
      this.categoryList = res.data;
      $('.loader').fadeOut(200);
    }, err => console.log(err));
  }

}
