import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Message} from '../interfaces/model';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SendMessageService {

  constructor(private http: HttpClient) { }

  static getLanguage(): number {
    if (localStorage.getItem('dir') === 'ltr') {
      return 1;
    } else {
      return 0;
    }
  }

  sendMessage(message: Message): Observable<any> {
    return this.http.post(`${environment.proxyServer + environment.apiUrl}enduser/send_message`, message);
  }

  getContact(): Observable<any> {
    return this.http.get(`${environment.proxyServer + environment.apiUrl}enduser/contact_us/${SendMessageService.getLanguage()}`);
  }
}
