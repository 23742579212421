<div class="content">
  <header class="d-flex align-items-center justify-content-center">
    <h4 class="header-text uk-margin-remove"><label routerLink="/home">{{'home' | translate}}</label> / {{'network' | translate}}</h4>
  </header>

  <div class="map">
    <div class="">
      <agm-map [zoom]="5" [latitude]="+lat" [longitude]="+lng" >
        <agm-marker *ngFor="let d of locationList" [latitude]="+d?.lat" [longitude]="+d?.lng">
<!--          <agm-info-window [longitude]="d.lng" [latitude]="d.lat" [isOpen]="false"></agm-info-window>-->
        </agm-marker>
      </agm-map>
    </div>
  </div>
</div>
