import {Component, OnDestroy, OnInit} from '@angular/core';
import {AvailableService} from '../../services/available.service';
import {Availability, Product} from '../../interfaces/model';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment.prod';

declare const $: any;

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit, OnDestroy {
  isArabic: boolean;
  productSub: Subscription;
  productList: Availability = {
    available: [],
    reserved: []
  };
  searchKey = '';
  media = environment.media + 'products/';
  allProducts: Product[] = [];

  constructor(private products: AvailableService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    localStorage.getItem('dir') === 'ltr' ? this.isArabic = false : this.isArabic = true;

    this.getAllProducts();
  }

  ngOnDestroy(): void {
    this.productSub.unsubscribe();
  }

  getAllProducts() {
    this.productSub = this.products.getAllProducts().subscribe(res => {
      this.productList = res.data;
      this.allProducts = res.data;
      this.hideOverlay();
    }, err => console.log(err));
  }

  search() {
    $('.loading').fadeIn(100);
    if (this.searchKey === '' || this.searchKey === undefined ) {
      this.getAllProducts();
      $('.loading').fadeOut(100);
    } else {
      $('.loading').fadeIn(100);
      this.products.search(this.searchKey).subscribe(res => {
        this.productList.available =  res.data.available.flat();
        this.productList.reserved = res.data.reserved.flat();
        $('.loading').fadeOut(100);
        this.hideOverlay();
      });
    }
  }

  // search() {
  //
  // }

  hideOverlay() {
    $('.main-loader').fadeOut(200);
  }

  showOverlay() {
    $('.main-loader').fadeIn(200);
  }

}
