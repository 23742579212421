import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AvailableService {

  static getLanguage(): number {
    if (localStorage.getItem('dir') === 'ltr') {
      return 1;
    } else {
      return 0;
    }
  }


  constructor(private http: HttpClient) {
  }

  getAllProducts(): Observable<any> {
    return this.http.get(`${environment.proxyServer + environment.apiUrl}enduser/all_products/${AvailableService.getLanguage()}`);
  }

  search(search: string): Observable<any> {
    return this.http.get(`${environment.proxyServer + environment.apiUrl}enduser/search/${search}`);
  }
}
