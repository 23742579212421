<div class="content">
  <div class="loader main-loader">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 mb-4" *ngFor="let product of products">
        <div class="product" uk-scrollspy="cls: uk-animation-fade; target: .uk-card; delay: 200;"
             (click)="route(product?.id, product.category_id)">
          <div class="uk-card uk-card-default">
            <div class="uk-card-media-top" [ngStyle]="{'background-image': 'url(' + media + product?.image + ')'}">
              <!--              <img [src]="media + product?.image" alt="" uk-img>-->
            </div>
            <div class="uk-card-body">
              <div class="d-flex align-items-center justify-content-sm-around flex-sm-wrap flex-wrap">
                <div class="location d-flex align-items-center">
                  <i class="fa fa-barcode" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;
                  <span class="text-uppercase">{{product?.code}}</span>
                </div>
                <div class="code  d-flex align-items-center px-sm-2 px-2">
                  <i class="fa fa-map-marker-alt" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;
                  <span class="-product-location">{{product?.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="col-12" *ngIf="products.length === 0 ">
        <div class="alert alert-primary" role="alert">
          <strong>{{('productsPage.no-data-found' | translate) + ' ' + ('productsPage.for-this-category' | translate)}}</strong>
        </div>
      </div>

    </div>
  </div>
</div>
