<footer>
  <div class="container">
    <div class="row">

      <div class="col-lg-3 mb-4">
        <div class="mb-4">
          <img src="assets/logo-white.png" alt="logo" width="60" uk-img>
        </div>
        <div class="desc">
          <span translate>footer-description</span>
        </div>
      </div>

      <div class="col-lg-3 offset-lg-1 mb-4">
        <h4 class="links-header" translate>useful-links</h4>
        <ul class="list-unstyled links p-0">
          <li class="mb-2"><a routerLink="/home" translate>home</a></li>
<!--          <li class="mb-2"><a href="" routerLink="/products" translate>products</a></li>-->
          <li class="mb-2"><a routerLink="/about-us" translate>about-us</a></li>
          <li class="mb-2"><a routerLink="/availability" translate>availability</a></li>
          <li class="mb-2"><a routerLink="/network" translate>network</a></li>
          <li class="mb-2"><a routerLink="/contact-us" translate>question-contact</a></li>
        </ul>
      </div>

      <div class="col-lg-3 mb-4" *ngIf="images.length > 0">
        <h4 class="links-header" translate>gallery</h4>
        <div class="row no-gutters" uk-lightbox="animation: slide">
          <div class="col-6 mb-2 pr-2 " *ngFor="let image of images">
            <a class="uk-inline" [href]="media + image?.image">
              <img [src]="media + image?.image" alt="gallery-item">
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-2 align-self-center social mb-4">
        <div class="d-flex align-items-center justify-content-start flex-wrap">
          <ng-container *ngFor="let social of socialMedia">
            <a class="mr-3 mb-2"  [href]="social?.link" target="_blank" *ngIf="social.type !== 'whatsapp' ">
              <i class="fab fa-{{social?.type}}"></i>
            </a>

            <a class="mr-3 mb-2"  [href]="'https://api.whatsapp.com/send?phone=' + social?.link" target="_blank" *ngIf="social.type === 'whatsapp' ">
              <i class="fab fa-{{social?.type}}"></i>
            </a>
          </ng-container>
        </div>
      </div>

    </div>
  </div>
</footer>
