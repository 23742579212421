<section class="content">
  <!--  slider header-->
  <div class="loader main-loader">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
  <header>
    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1"
         uk-slideshow="ratio: false; autoplay: true; autoplay-interval: 3000; min-height: 500; max-height: 600; animation: push">

      <ul class="uk-slideshow-items">
        <li *ngFor="let image of images">
          <img [src]="media + image.image" alt="" uk-cover>
          <div class="uk-overlay uk-position-cover overlay"></div>
          <!--          <div class="uk-position-center uk-text-center">-->
          <!--            <h2 uk-slider-parallax="x: 100,-100" style="text-align: center !important;">الخدمات</h2>-->
          <!--            <p uk-slider-parallax="x: 200,-200" style="text-align: center !important;">Lorem ipsum dolor sit amet.</p>-->
          <!--          </div>-->
        </li>
      </ul>

      <!--      <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>-->
      <!--      <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>-->

    </div>
  </header>

  <!--  Our Services-->
  <section id="services" class="services">

    <div class="header text-center mb-5">
      <h2 class="header-text" translate>our-services</h2>
    </div>

    <div class="row no-gutters">
      <div class="col-md-5 left-section">
        <img [src]="media + services?.image" alt="image" class="img-fluid" uk-cover>
      </div>
      <div class="col-md-7 right-section">
        <div class="services-text">
          <!--          <h4 class="heading mb-1">Looking for best service and creative work ?</h4>-->
          <!--          <p class="desc uk-margin-remove">-->
          <!--            Maqsoud Media is all about adding value to your marketing communications! Our various in-house capabilities-->
          <!--            and resources of: Creative Design, Media Planning & Booking, all are seamlessly integrated for your campaign-->
          <!--            to be in-focus and picture-perfect!-->
          <!--          </p>-->

          <!--          <h4 class="heading mb-1">Creative Design</h4>-->
          <!--          <p class="desc uk-margin-remove">-->
          <!--            In-house capabilities and state of the art setup for designing your message to perfection!-->
          <!--          </p>-->

          <!--          <h4 class="heading mb-1">Media Planning & Booking </h4>-->
          <!--          <p class="desc uk-margin-remove">-->
          <!--            Integrating all your media need for an effective and consistent message to your target audience-->
          <!--          </p>-->

          <div class="ck" [innerHTML]="services?.body"></div>

        </div>
      </div>
    </div>

  </section>

  <!-- Our products -->
  <section class="products">
    <div class="container">
      <div class="header text-center mb-5">
        <h2 class="header-text" translate>our-products</h2>
      </div>

      <!--    Products -->

      <ul class="nav nav-pills mb-4 d-flex align-items-center justify-content-center" id="pills-tab" role="tablist">
        <li class="nav-item mx-1 mb-1 mb-md-0">
          <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
             aria-controls="pills-home" aria-selected="true" translate>advertising</a>
        </li>
        <li class="nav-item mx-1 mb-1 mb-md-0">
          <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
             aria-controls="pills-profile" aria-selected="false" translate>social-media</a>
        </li>
        <li class="nav-item mx-1 mb-1 mb-md-0">
          <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab"
             aria-controls="pills-contact" aria-selected="false" translate>tv-radio</a>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

          <div class="row">

            <div class="col-md-6">
              <div class="product mb-3 first uk-card" [routerLink]="['/products/', categoryList[0]?.id]">
                <div style="position:relative" class="uk-card-media-top"
                     [ngStyle]="{'background-image': 'url(' +  media + categoryList[0]?.image +')'}">
                  <!--              <img [src]="media + categoryList[0]?.image" alt="product">-->
                  <div class="uk-overlay uk-position-cover product-overlay"></div>
                  <div class="uk-overlay uk-position-bottom overlay-text">
                    <h3 class="heading mb-1 px-3">{{categoryList[0]?.name}}</h3>
                    <p class="desc uk-margin-remove px-3">{{categoryList[0]?.des}}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="product mb-3 second uk-card" [routerLink]="['/products/', categoryList[1]?.id]">
                <div style="position:relative" class="uk-card-media-top"
                     [ngStyle]="{'background-image': 'url(' +  media + categoryList[1]?.image +')'}">
                  <!--              <img [src]="media + categoryList[1]?.image" alt="product">-->
                  <div class="uk-overlay uk-position-cover product-overlay"></div>
                  <div class="uk-overlay uk-position-bottom overlay-text">
                    <h3 class="heading mb-1 px-3">{{categoryList[1]?.name}}</h3>
                    <p class="desc uk-margin-remove px-3">{{categoryList[0]?.des}}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="product mb-3 third uk-card" [routerLink]="['/products/', categoryList[2]?.id]">
                <div style="position:relative" class="uk-card-media-top"
                     [ngStyle]="{'background-image': 'url(' +  media + categoryList[2]?.image +')'}">
                  <!--              <img [src]="media + categoryList[2]?.image" alt="product">-->
                  <div class="uk-overlay uk-position-cover product-overlay"></div>
                  <div class="uk-overlay uk-position-bottom overlay-text">
                    <h3 class="heading mb-1 px-3">{{categoryList[2]?.name}}</h3>
                    <p class="desc uk-margin-remove px-3">{{categoryList[2]?.des}}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="product mb-3 uk-card forth" [routerLink]="['/products/', categoryList[3]?.id]">
                <div style="position:relative" class="uk-card-media-top"
                     [ngStyle]="{'background-image': 'url(' +  media + categoryList[3]?.image +')'}">
                  <!--              <img [src]="media + categoryList[3]?.image" alt="product">-->
                  <div class="uk-overlay uk-position-cover product-overlay"></div>
                  <div class="uk-overlay uk-position-bottom overlay-text">
                    <h3 class="heading mb-1 px-3">{{categoryList[3]?.name}}</h3>
                    <p class="desc uk-margin-remove px-3">{{categoryList[3]?.des}}</p>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <div class="row">
            <div class="col-md-8 social">
              <p class="description text-muted" translate>social-description</p>
              <a class="btn">
                <a translate  class="text-decoration-none text-white" routerLink="/social-media">see-plans-btn</a>
                <i class="fa mx-2" [ngClass]="{'fa-chevron-left': isArabic, 'fa-chevron-right': !isArabic}" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
          <div class="row">
            <div class="col-md-8 tv-radio">
              <p class="description text-muted" translate>tv-description</p>
              <a class="btn">
                <a translate routerLink="/tv-radio-advertisement" class="text-decoration-none text-white">subscribe-now</a>
                <i class="fa mx-2" [ngClass]="{'fa-chevron-left': isArabic, 'fa-chevron-right': !isArabic}" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>


    </div>
  </section>

  <!--  latest news -->
  <section class="news">
    <div class="container">
      <div class="header text-center mb-5">
        <h2 class="header-text" translate>latest-news</h2>
      </div>
      <div class="row">

        <div class="col-md-6 mb-4" *ngFor="let news of homeData.news">
          <div class="single-news">
            <div class="uk-card uk-card-default" uk-lightbox>
              <div class="uk-card-media-top">
                <a [href]="media + news.image">
                  <img [src]="media + news.image" alt="news">
                </a>
              </div>
              <div class="uk-card-body">
                <!--                <h3 class="uk-card-title">Header</h3>-->
                <p>{{news.body}}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

</section>
