import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SocialMediaService} from '../../services/social-media.service';
import {TVs, Request} from '../../interfaces/model';
import {forkJoin, Subscription} from 'rxjs';
import Uikit from 'uikit';
import {NgForm} from "@angular/forms";

declare const $: any;

@Component({
  selector: 'app-tv-radio',
  templateUrl: './tv-radio.component.html',
  styleUrls: ['./tv-radio.component.scss']
})
export class TvRadioComponent implements OnInit, OnDestroy {
  imgPath = 'http://development.api.ieeeshasb.org/public/tv/';
  isArabic: boolean;
  tvsContainer: TVs[] = [];
  radioContainer: TVs[] = [];
  channelsSub: Subscription;
  headerImage: string;
  messageIsBeingSend: boolean;

  messageContainer: Request = {
   name: '',
    address: '',
    company: '',
    email: '',
    id_item: null,
    phone: null,
    type: ''
  };
  @ViewChild('contactForm', {static: false}) contactForm: NgForm;

  constructor(private social: SocialMediaService) {
  }

  ngOnInit(): void {
    this.messageIsBeingSend = false;
    window.scrollTo(0, 0);
    window.localStorage.getItem('dir') === 'ltr' ? this.isArabic = false : this.isArabic = true;
    window.localStorage.getItem('dir') === 'ltr' ? this.headerImage = 'assets/images/social-media.svg' : this.headerImage = 'assets/images/tv-radio-rtl.svg';

    this.getAllChannels();
  }

  ngOnDestroy(): void {
    this.channelsSub.unsubscribe();
  }

  getAllChannels() {
    this.channelsSub = forkJoin([this.social.getAllTvs(0), this.social.getAllTvs(1)]).subscribe(res => {
      this.tvsContainer = res[0].data;
      this.radioContainer = res[1].data;
      $('.loader').fadeOut(200);
    });
  }

  sendMessage() {
    console.log(this.messageContainer);
    this.messageIsBeingSend = true;
    this.social.makeRequest(this.messageContainer).subscribe(() => {
      $('.modal').modal('toggle');
      Uikit.notification('We have received your request', {pos: 'top-center'});
      this.contactForm.reset();
      this.messageIsBeingSend = false;
    }, err => console.log(err));
  }
}
