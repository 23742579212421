import {Component, OnInit} from '@angular/core';
import {LanguageService} from './services/language.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  dir: string;

  constructor(private translate: TranslateService) {

  }

  ngOnInit(): void {
    this.translate.setDefaultLang('en');
    if (LanguageService.getDirection()) {
      this.dir = LanguageService.getDirection();
      document.body.dir = this.dir;
    } else {
      LanguageService.setEnglishDirection();
      document.body.dir = 'ltr';
    }
    localStorage.getItem('dir') === 'ltr' ? this.translate.use('en') : this.translate.use('ar');
  }

}
