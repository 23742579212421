<!--loader-->
<div class="loader main-loader">
  <div class="spinner">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</div>

<section class="content">
  <header>
    <div class="uk-cover-container">
      <canvas width="" height="350"></canvas>
      <img src="assets/images/02.jpg" alt="" uk-cover>
      <div class="uk-overlay uk-position-cover overlay"></div>
      <div class="uk-overlay uk-position-center overlay-text">
        <h2 class="text">{{'contactPage.header-text' | translate}}</h2>
      </div>
    </div>
  </header>

  <!--  contact info -->
  <section class="contact-info">
    <div class="container">

      <div class="head mb-5">
        <h4 class="colored uk-margin-remove">{{'contactPage.be-in-touch' | translate}}</h4>
        <h1 class="heading mt-3">{{'contactPage.contact-us' | translate}}</h1>
        <p class="desc">{{('contactPage.des' | translate) | slice:0:59}}<br/>
          {{('contactPage.des' | translate) | slice:60}}</p>
      </div>

      <div class="contact-types">
        <div class="row">

          <div class="col-12 col-lg-4 col-md-6 col-sm-12 mb-3">
            <div class="contact-type" >
              <div class="icon mb-4 address">
                <i class="fa fa-location-arrow" aria-hidden="true"></i>
              </div>
              <div class="type">
                <p class="mb-3">{{'contactPage.address' | translate}}</p>
              </div>
              <div class="info">
                <ul class="list-unstyled mb-0">
<!--                  <li class="text-muted"><a>19 babel St, Iraq NY 1002</a></li>-->
                  <li class="text-muted" *ngFor="let address of contactInfo?.address"><a>{{address.address}}</a></li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-4 col-md-6 col-sm-12 mb-3">
            <div class="contact-type">
              <div class="icon mb-4 phone">
                <i class="fa fa-phone" aria-hidden="true"></i>
              </div>
              <div class="type">
                <p class="mb-3">{{'contactPage.phone' | translate}}</p>
              </div>
              <div class="info">
                <ul class="list-unstyled mb-0">
<!--                  <li class="text-muted"><a>+06626532625</a></li>-->
<!--                  <li class="text-muted"><a>+06626532625</a></li>-->
                  <li class="text-muted" *ngFor="let phone of contactInfo.phones"><a>{{ phone.phone }}</a></li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-4 col-md-6 col-sm-12 mb-3">
            <div class="contact-type">
              <div class="icon mb-4 email">
                <i class="fa fa-paper-plane" aria-hidden="true"></i>
              </div>
              <div class="type">
                <p class="mb-3">{{'contactPage.email' | translate}}</p>
              </div>
              <div class="info">
                <ul class="list-unstyled mb-0">
<!--                  <li class="text-muted"><a>info@maqsoudland.com</a></li>-->
<!--                  <li class="text-muted"><a>info@maqsoudland.com</a></li>-->
                  <li class="text-muted" *ngFor="let email of contactInfo.emails"><a>{{ email.email }}</a></li>
                </ul>
              </div>
            </div>
          </div>

<!--          <div class="col-12 col-lg-3 col-md-6 col-sm-12 mb-3">-->
<!--            <div class="contact-type">-->
<!--              <div class="icon mb-4 hours">-->
<!--                <i class="fa fa-business-time" aria-hidden="true"></i>-->
<!--              </div>-->
<!--              <div class="type">-->
<!--                <p class="mb-3">business hours</p>-->
<!--              </div>-->
<!--              <div class="info">-->
<!--                <ul class="list-unstyled mb-0">-->
<!--&lt;!&ndash;                  <li class="text-muted"><a>Mon-fri: 10 am - 6 pm Sat-sun: holiday</a></li>&ndash;&gt;-->
<!--                  <li class="text-muted"><a></a></li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

        </div>
      </div>

    </div>
  </section>

  <!--  Contact form -->
  <section class="contact-form-section">
    <div class="container">

      <div class="header">
        <h4 class="colored uk-margin-remove">{{'contactPage.be-in-touch' | translate}}</h4>
        <h2 class="mt-1 heading">{{'contactPage.send-message' | translate}}</h2>
      </div>


      <div class="row mb-4">
        <div class="col-lg-6">
          <div class="form">
            <p class="text-muted mb-5">{{'contactPage.form-des' | translate}}</p>

            <form #contactForm="ngForm" (ngSubmit)="sendMessage()">
              <div class="form-group">

                <div class="row ">
                  <div class="col-12 col-sm-6 mb-4">
                    <input name="name" type="text" [class.invalid]="" [placeholder]="'contactPage.name-placeholder'| translate" class= "form-control"
                           required [(ngModel)]="messageContainer.name">
                  </div>
                  <div class="col-12 col-sm-6 mb-4">
                    <input name="phone" [class.invalid]="" onkeypress="validate(event)" type="tele" [placeholder]="'contactPage.phone-placeholder'| translate"
                           class="form-control" required [(ngModel)]="messageContainer.phone">
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-6 mb-4">
                    <input type="email" #email email name="email"[placeholder]="'contactPage.mail-placeholder'| translate"
                           class="form-control" required [(ngModel)]="messageContainer.email">
                    <small class="text-danger" *ngIf="contactForm.controls.email?.errors?.email">is not an email format</small>
                  </div>
                  <div class="col-12 col-sm-6 mb-4">
                    <input type="text" [class.invalid]="" name="company" [placeholder]="'contactPage.comp-placeholder'| translate" class="form-control"
                           required [(ngModel)]="messageContainer.company">
                  </div>
                </div>

                <textarea name="body" [class.invalid]="" [placeholder]="'contactPage.message-placeholder'| translate" class="form-control mb-4"
                          rows="5" [(ngModel)]="messageContainer.body" required></textarea>
                <button type="submit" class="btn"
                        [disabled]="messageIsBeingSend || contactForm.invalid">{{(messageIsBeingSend ? 'contactPage.form-btn-loading' : 'contactPage.form-btn') | translate}}</button>


              </div>

            </form>
          </div>
        </div>
        <div class="col-lg-6">
          <agm-map [zoom]="8" [latitude]="data[0].lat" [longitude]="data[0].lng">
            <agm-marker *ngFor="let d of data" [latitude]="d.lat" [longitude]="d.lng"></agm-marker>
          </agm-map>
        </div>
      </div>


    </div>
  </section>

</section>
