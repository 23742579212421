import { Component, OnInit } from '@angular/core';
import {NetworkService} from '../../services/network.service';
import {Locations} from '../../interfaces/model';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit {
  // data = [
  //   {lat: 50.678418, lng: 8.089007},
  //   {lat: 52.678418, lng: 9.089007}
  // ];
  lat = null;
  lng = null;

  locationList: Locations[] = [];

  constructor(private locations: NetworkService) {
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getLocations();
  }

  getLocations() {
    this.locations.getLocations().subscribe(res => {
      this.locationList = res.data;
      console.log(this.locationList);
      this.lat = res.data[0].lat;
      this.lng = res.data[0].lng;
    }, err => console.log(err));
  }

}
