<div class="" style="z-index: 980;" uk-sticky>
  <nav class="navbar navbar-expand-lg navbar-light global" [ngClass]="{'ar': isArabic, 'en': !isArabic}">
    <div class="container">
      <a class="navbar-brand" routerLink="/"><img src="assets/logo-white.png" alt="logo" width="60"></a>
      <button class="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId"
              aria-controls="collapsibleNavId"
              aria-expanded="false" aria-label="Toggle navigation">
        <!--                <span class="navbar-toggler-icon"></span>-->
        <i class="fas fa-bars" aria-hidden="true"></i>
      </button>
      <div class="collapse navbar-collapse" id="collapsibleNavId">
        <ul class="navbar-nav mt-2 mt-lg-0">
          <li class="nav-item">
            <a class="nav-link" routerLink="/home" routerLinkActive="nav-active" translate>home <span class="sr-only">(current)</span></a>
          </li>
<!--          <li class="nav-item">-->
<!--            <a class="nav-link" routerLink="/products/{{categoryList[0]?.id}}" routerLinkActive="nav-active" translate>products</a>-->
<!--          </li>-->

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" translate href="#" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              products
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
              <a class="dropdown-item" translate routerLink="/products/{{categoryList[0]?.id}}">advertising</a>
              <a class="dropdown-item" translate routerLink="/social-media">social-media</a>
              <a class="dropdown-item" translate routerLink="/tv-radio-advertisement">tv-radio</a>
            </div>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/network" routerLinkActive="nav-active" translate>network</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="availability" routerLinkActive="nav-active" translate>availability</a>
          </li>
<!--          <li class="nav-item">-->
<!--            <a class="nav-link" uk-scroll href="#services" routerLink="/home" translate>services</a>-->
<!--          </li>-->
          <li class="nav-item">
            <a class="nav-link" routerLink="/about-us" routerLinkActive="nav-active" translate>about-us</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{isArabic ? 'اللغة' : 'Language'}}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item arabic" (click)="setArabic()">العربية</a>
              <a class="dropdown-item english" (click)="setEnglish()">English</a>
            </div>
          </li>
          <li class="nav-item">
            <button class="btn" routerLink="/contact-us" translate>question-contact</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
