import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() { }

  static getDirection(): string {
    return localStorage.getItem('dir');
  }

  static setArabicDirection(): void {
    localStorage.setItem('dir', 'rtl');
    document.body.dir = 'rtl';
    window.location.href = '/';
  }

  static setEnglishDirection(): void {
    localStorage.setItem('dir', 'ltr');
    document.body.dir = 'ltr';
    window.location.href = '/';
  }

  static toggleDirection(): void {
    if (LanguageService.getDirection() === 'ltr') {
        LanguageService.setArabicDirection();
        window.location.reload();
    } else {
      LanguageService.setEnglishDirection();
      window.location.reload();
    }
  }
}
