import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AboutUsService {

  static getLanguage(): number {
    if (localStorage.getItem('dir') === 'ltr') {
      return 1;
    } else {
      return 0;
    }
  }


  constructor(private http: HttpClient) {
  }

  getAbout(): Observable<any> {
    return this.http
      .get(`${environment.proxyServer + environment.apiUrl}dashboard/get_about_us/${AboutUsService.getLanguage()}`);
  }
}
