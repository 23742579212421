import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  static getLanguage(): number {
    if (localStorage.getItem('dir') === 'ltr') {
      return 1;
    } else {
      return 0;
    }
  }

  constructor(private http: HttpClient) {
  }

  getHomeData(): Observable<any> {
    return this.http
      .get(`${environment.proxyServer + environment.apiUrl}enduser/home_data/${HomeService.getLanguage()}`);
  }

  getServices(): Observable<any> {
    return this.http
      .get(`${environment.proxyServer + environment.apiUrl}dashboard/get_service/${HomeService.getLanguage()}`);
  }
}
