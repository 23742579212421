import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Route, RouterModule} from '@angular/router';
import {ProductsComponent} from './products.component';
import {ProductComponent} from './product/product.component';
import {SingleProductComponent} from './single-product/single-product.component';

const routes: Route[] = [
  {
    path: 'products',
    component: ProductsComponent,
    children: [
      {
        path: ':id',
        component: ProductComponent
      },
      {
        path: ':id/:id',
        component: SingleProductComponent
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
