import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoryProductService} from '../../../services/category-product.service';
import {Product} from '../../../interfaces/model';
import {Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment.prod';

declare const $: any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {

  products: Product[] = [];
  productSub: Subscription;
  paramsSub: Subscription;
  media = environment.media + 'products/';
  categoryId: string;
  isArabic: boolean;

  constructor(private r: ActivatedRoute, private router: Router, private categoryProduct: CategoryProductService) { }

  ngOnInit(): void {
    localStorage.getItem('dir') === 'ltr' ? this.isArabic = false : this.isArabic = true;
    this.paramsSub = this.r.params.subscribe(params => {
      this.categoryId = params.id;
      $('.loader').fadeIn(200);
      this.getProducts();
    });
  }

  ngOnDestroy(): void {
    this.productSub.unsubscribe();
    this.paramsSub.unsubscribe();
  }

  route(id, catId) {
    this.router.navigate(['../', catId, id], { relativeTo: this.r });
  }

  getProducts() {
    this.productSub = this.categoryProduct.getCategoryProducts(this.categoryId).subscribe(res => {
      this.products = res.data['data'];
      console.log(res);
      $('.loader').fadeOut(200);
    }, err => console.log(err));
  }

}
