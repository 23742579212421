import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  getSocial(): Observable<any> {
    return this.http.get(`${environment.proxyServer + environment.apiUrl}dashboard/all_socials`);
  }
}
