<!--loader-->
<div class="loader main-loader">
  <div class="spinner">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</div>

<section class="content global" [ngClass]="{'en': !isArabic, 'ar': isArabic}">
  <header class="d-flex align-items-center justify-content-center">
    <h4 class="header-text uk-margin-remove"><label routerLink="/home">{{'home' | translate}}</label>
      / {{'availability' | translate}}</h4>
  </header>
  <div class="search-section">
    <form class="uk-search uk-search-default uk-inline" (ngSubmit)="search()">
      <button type="submit" uk-search-icon></button>
      <input class="uk-search-input" name="search" (input)="search()" [(ngModel)]="searchKey" type="search"
             [placeholder]="'availablePage.search-placeholder' | translate">
    </form>
  </div>

  <div class="products">
<!--    <div class="loading">-->
<!--      <div class="wrapper">-->
<!--        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>-->
<!--      </div>-->
<!--    </div>-->

    <div class="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="container">
      <!--      available -->
      <div class="available">
        <!--        header -->
        <div class="header d-flex align-items-center justify-content-between">
          <h2 class="uk-margin-remove header-text">{{'availablePage.available-header' | translate}}</h2>
          <a class="btn" routerLink="/contact-us">{{'aboutPage.contact-btn' | translate}}</a>
        </div>
        <!--        products preview -->
        <div class="product-list" uk-scrollspy="cls: uk-animation-fade; target: .uk-card; delay: 200; repeat: false">
          <div class="product-preview uk-card uk-card-default"
               *ngFor="let available of productList.available"
               routerLink="/products/{{available?.category_id}}/{{available?.id}}">
            <div class="image">
              <img [src]="media + available.image" alt="img" class="img-fluid">
            </div>
            <div class="info">
              <div class="d-flex align-items-start justify-content-around flex-column">
                <div class="location d-flex align-items-center">
                  <i class="fa fa-barcode" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                  <h3 class="uk-margin-remove">{{available.code}}</h3>
                </div>
                <div class="code  d-flex align-items-center">
                  <i class="fa fa-map-marker-alt" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <h3 class="uk-margin-remove">{{available.name}}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-card uk-card-default no-data mt-3" *ngIf="productList.available.length === 0">
          <div class="uk-card-body">
            <p class="text-info font-weight-bold">{{'availablePage.no-available' | translate}}</p>
          </div>
        </div>

      </div>
      <!--      Reserved-->
      <hr/>
      <div class="reserved">
        <!--        header -->
        <div class="header p-0 d-flex align-items-center justify-content-start">
          <h2 class="uk-margin-remove header-text">{{'availablePage.reserved-header' | translate}}</h2>
          <!--          <a class="btn">Contact Us!</a>-->
        </div>
        <!--        products preview -->
        <div class="product-list">
          <div class="product-preview uk-card uk-card-default" *ngFor="let reserved of productList.reserved"
               routerLink="/products/{{reserved?.category_id}}/{{reserved?.id}}">
            <div class="image">
              <img [src]="media + reserved.image" alt="img" class="img-fluid">
            </div>
            <div class="info">
              <div class="d-flex align-items-start justify-content-around flex-column">
                <div class="location d-flex align-items-center">
                  <i class="fa fa-barcode" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                  <h3 class="uk-margin-remove">{{reserved.code}}</h3>
                </div>
                <div class="code  d-flex align-items-center">
                  <i class="fa fa-map-marker-alt" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <h3 class="uk-margin-remove">{{reserved.name}}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="uk-card uk-card-default no-data mt-3" *ngIf="productList.reserved.length === 0">
          <div class="uk-card-body">
            <p class="text-info font-weight-bold">{{'availablePage.no-reserved' | translate}}</p>
          </div>
        </div>

      </div>
    </div>
  </div>

</section>
