import {Component, OnDestroy, OnInit} from '@angular/core';
import {GalleryService} from '../../services/gallery.service';
import {Slider, Social} from '../../interfaces/model';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment.prod';
import {ContactService} from '../../services/contact.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  images: Slider[] = [];
  gallerySub: Subscription;
  media = environment.media + 'gallery/';

  socialMedia: Social[] = [];

  constructor(private gallery: GalleryService, private social: ContactService) { }

  ngOnInit(): void {
    this.getGallery();
    this.getSocial();
  }

  ngOnDestroy(): void {
    this.gallerySub.unsubscribe();
  }

  getGallery() {
    this.gallerySub = this.gallery.getGallery().subscribe(res => {
      this.images = res.data.data.slice(0, 4);
    });
  }

  getSocial() {
    this.social.getSocial().subscribe(res => {
      this.socialMedia = res.data;
      console.log(res);
    });
  }

}
