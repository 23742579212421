import {Component, OnDestroy, OnInit} from '@angular/core';
import {SpecificProductService} from '../../../services/specific-product.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {Product} from '../../../interfaces/model';
import {environment} from '../../../../environments/environment.prod';

declare const $: any;

@Component({
  selector: 'app-single-product',
  templateUrl: './single-product.component.html',
  styleUrls: ['./single-product.component.scss']
})
export class SingleProductComponent implements OnInit, OnDestroy {

  isArabic: boolean;
  productId: string;
  productSub: Subscription;
  paramsSub: Subscription;
  media = environment.media + 'products/';
  productDetails: Product = {
    name: '',
    code: '',
    image: '',
    des: '',
    direction: '',
    faces: '',
    price: '',
    Production: '',
    space: '',
    category_id: ''
  };

  constructor(private product: SpecificProductService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    localStorage.getItem('dir') === 'ltr' ? this.isArabic = false : this.isArabic = true;
    this.paramsSub = this.route.params.subscribe(res => {
      this.productId = res.id;
      this.getProductDetails();
    });
  }

  ngOnDestroy(): void {
    this.productSub.unsubscribe();
    this.paramsSub.unsubscribe();
  }

  getProductDetails() {
    this.productSub = this.product.getProduct(this.productId).subscribe(res => {
      this.productDetails = res.data;
      $('.loader').fadeOut(200);
      console.log(this.productDetails);
    }, err => console.log(err));
  }

}
