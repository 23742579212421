import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './routes/home/home.component';
import {AboutComponent} from './routes/about/about.component';
import {ContactComponent} from './routes/contact/contact.component';
import {AvailabilityComponent} from './routes/availability/availability.component';
import {NetworkComponent} from './routes/network/network.component';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {TvRadioComponent} from './routes/tv-radio/tv-radio.component';
import {SocialMediaComponent} from './routes/social-media/social-media.component';


const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'about-us', component: AboutComponent},
  {path: 'contact-us', component: ContactComponent},
  {path: 'availability', component: AvailabilityComponent},
  {path: 'network', component: NetworkComponent},
  {path: 'tv-radio-advertisement', component: TvRadioComponent},
  {path: 'social-media', component: SocialMediaComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }), BrowserModule, CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
