import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment.prod';
import {Request} from '../interfaces/model';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService {

  constructor(private http: HttpClient) { }
  // ********** GET ALL PLANS ********** //
  getAllPlans(lang): Observable<any> {
    return this.http.get(`${environment.apiUrl}dashboard/all_plans/${lang}`);
  }

  // ********** GET ALL TVs ********** //
  getAllTvs(type): Observable<any> {
    return this.http.get(`${environment.apiUrl}dashboard/all_tvs/${type}`);
  }

  makeRequest(request: Request): Observable<any> {
    return this.http.post(`${environment.apiUrl}enduser/make_request`, request);
  }
}
