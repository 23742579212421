import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductComponent } from './product/product.component';
import {RouterModule} from '@angular/router';
import {SingleProductComponent} from './single-product/single-product.component';
import {ProductsComponent} from './products.component';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
  declarations: [ProductComponent, SingleProductComponent, ProductsComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule
  ], exports: [ProductComponent, SingleProductComponent]
})
export class ProductsModule { }
