<section class="content">
  <div class="loader main-loader">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
  <div class="global" [ngClass]="{'en': !isArabic, 'ar': isArabic}">
    <header>
      <div class="uk-cover-container">
        <canvas width="" height="350"></canvas>
        <img src="assets/images/02.jpg" alt="" uk-cover>
        <div class="uk-overlay uk-position-cover overlay"></div>
        <div class="uk-overlay uk-position-center overlay-text">
          <h2 class="text">{{'home' | translate}} / {{'products' | translate}}</h2>
        </div>
      </div>
    </header>
    <div class="container">
      <!--      categories -->
      <div class="categories" id="routing">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-6 col-6 border{{ i + 1}} mb-2" *ngFor="let cat of categoryList; index as i"
               (click)="scrollToRoutingSection()">
            <div class="category-name class{{ i + 1 }}" [routerLink]="[cat.id]" routerLinkActive="subnav-active">
              <h4 class="category-text uk-margin-remove">{{ cat.name }}</h4>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!--      Router View-->
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
