import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SendMessageService} from '../../services/send-message.service';
import {ContactInfo, Message} from '../../interfaces/model';
import {NgForm} from '@angular/forms';
import Uikit from 'uikit';
import {Subscription} from 'rxjs';

declare const $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  @ViewChild('contactForm', {static: false}) contactForm: NgForm;

  messageIsBeingSend: boolean;
  contactSub: Subscription;
  contactInfo: ContactInfo = {
    address: [],
    emails: [],
    phones: []
  };
  messageContainer: Message = {
    name: '',
    company: '',
    body: '',
    email: '',
    phone: null
  };

  constructor(private contact: SendMessageService) { }

  data = [
    {lat: 33.315050, lng: 44.358642}
  ];

  ngOnInit(): void {
    this.messageIsBeingSend = false;
    this.getContactInfo();
    window.scrollTo(0, 0);
  }

  ngOnDestroy(): void {
    this.contactSub.unsubscribe();
  }

  sendMessage() {
    this.messageIsBeingSend = true;
    this.contact.sendMessage(this.messageContainer).subscribe(() => {
      Uikit.notification('We have received your message', {pos: 'top-center'});
      this.contactForm.reset();
      this.messageIsBeingSend = false;
    }, err => console.log(err));
  }

  getContactInfo() {
    this.contactSub = this.contact.getContact().subscribe(res => {
      this.contactInfo = res.data;
      this.hideOverlay();
    }, err => console.log(err));
  }

  hideOverlay() {
    $('.main-loader').fadeOut(200);
  }

}
