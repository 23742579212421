<section class="social-media pb-5">

  <div class="loader main-loader">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>

  <div class="upper-part" [ngStyle]="{'background-image': 'url('+ headerImage +')'}">
    <div class="container">
      <h2 class="content-caption" translate>social-media</h2>
    </div>
  </div>
  <div class="description-part my-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6 d-flex align-items-center">
          <div class="desc-info">
            <h3 class="desc-header" translate>description</h3>
            <p class="desc-content" translate>social-description</p>
          </div>
        </div>
        <div class="col-md-6 d-flex align-items-center justify-content-center">
          <img src="assets/images/social_girl.svg" class="img-fluid" width="400" alt="social-girl">
        </div>
      </div>
    </div>
  </div>

  <div class="plans-part">
    <div class="our-plans mb-5">
      <h2 class="plans-header" translate>our-plans</h2>
    </div>
    <div class="container">
      <div class="row plans">
        <div class="col-md-4 mt-3 plane-{{i + 1}}" *ngFor="let plan of plansContainer; index as i">
          <div class="plan-card">
            <div class="plan-img">
              <img *ngIf="plan.id === 1" src="assets/images/silver.png" class="img-fluid" alt="card-image">
              <img *ngIf="plan.id === 4" src="assets/images/silver.png" class="img-fluid" alt="card-image">
              <img *ngIf="plan.id === 2" src="assets/images/gold.png" class="img-fluid" alt="card-image">
              <img *ngIf="plan.id === 5" src="assets/images/gold.png" class="img-fluid" alt="card-image">
              <img *ngIf="plan.id === 3" src="assets/images/fayrouz.png" class="img-fluid" alt="card-image">
              <img *ngIf="plan.id === 6" src="assets/images/fayrouz.png" class="img-fluid" alt="card-image">
              <div class="card-overlayer">
                <div class="feature-name"><span>{{plan.name | titlecase}}</span></div>
                <div class="feature-price"><span class="f-currency">$</span><span class="f-price">{{plan.price}}</span></div>
              </div>
            </div>
            <div class="plan-description">
              <span>{{plan.des}}</span>
            </div>
            <div class="features-content" *ngFor="let feature of plan.plan_items">
              <div class="features-left">
                <span *ngIf="feature.type === 1" class="red-dot"></span>
                <span *ngIf="feature.type === 4" class="red-dot"></span>
                <span *ngIf="feature.type === 2" class="golden-dot"></span>
                <span *ngIf="feature.type === 5" class="golden-dot"></span>
                <span *ngIf="feature.type === 3" class="fayrouz-dot"></span>
                <span *ngIf="feature.type === 6" class="fayrouz-dot"></span>
                <span class="feature-caption text-muted">{{feature.name}}</span>
              </div>
            </div>
            <div [ngClass]="{'subscribe-red-plan' : plan.id === 1 || plan.id === 4 , 'subscribe-gold-plan' : plan.id === 2 || plan.id === 5, 'subscribe-fayrouz-plan' : plan.id === 3 || plan.id === 6}">
              <button translate
                      data-toggle="modal"
                      (click)="messageContainer.type = 'plan'; messageContainer.id_item = plan?.id"
                      data-target="#modelId"
              >subscribe-now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- Modal -->
<div class="modal fade" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <form class="modal-dialog form" role="document" #contactForm="ngForm" (ngSubmit)="sendMessage()">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center justify-content-between">
        <h5 class="modal-title" translate>enter-info</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="">
              <p class="text-muted mb-5">{{'contactPage.form-des' | translate}}</p>

              <div>
                <div class="form-group">

                  <div class="row ">
                    <div class="col-12 col-sm-6 mb-4">
                      <input name="name" type="text" [class.invalid]=""
                             [placeholder]="'contactPage.name-placeholder'| translate" class="form-control"
                             required [(ngModel)]="messageContainer.name">
                    </div>
                    <div class="col-12 col-sm-6 mb-4">
                      <input name="phone" [class.invalid]="" onkeypress="validate(event)" type="tele"
                             [placeholder]="'contactPage.phone-placeholder'| translate"
                             class="form-control" required [(ngModel)]="messageContainer.phone">
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-sm-6 mb-4">
                      <input type="email" #email email name="email"
                             [placeholder]="'contactPage.mail-placeholder'| translate"
                             class="form-control" required [(ngModel)]="messageContainer.email">
                      <small class="text-danger" *ngIf="contactForm.controls.email?.errors?.email">is not an email
                        format
                      </small>
                    </div>
                    <div class="col-12 col-sm-6 mb-4">
                      <input type="text" [class.invalid]="" name="company"
                             [placeholder]="'contactPage.comp-placeholder'| translate" class="form-control"
                             required [(ngModel)]="messageContainer.company">
                    </div>
                  </div>

                  <textarea name="body" [class.invalid]="" [placeholder]="'contactPage.message-placeholder'| translate"
                            class="form-control"
                            rows="5" [(ngModel)]="messageContainer.address" required></textarea>


                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn close-btn btn-secondary" data-dismiss="modal" translate>close</button>
        <button type="submit" class="btn submit"
                [disabled]="messageIsBeingSend || contactForm.invalid">{{(messageIsBeingSend ? 'contactPage.form-btn-loading' : 'contactPage.form-btn') | translate}}</button>
      </div>
    </div>
  </form>
</div>
